
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { VerticalAppHeaderComponent } from './layouts/full/vertical-header/vertical-header.component';
import { VerticalAppSidebarComponent } from './layouts/full/vertical-sidebar/vertical-sidebar.component';
import { AppBreadcrumbComponent } from './layouts/full/breadcrumb/breadcrumb.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './material-module';
import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { JwtInterceptor } from './authentication/helpers/jwt.interceptor';
import { ErrorInterceptor } from './authentication/helpers/error.interceptor';
import { AuthenticationService } from './authentication/authentication.service';
import { AuthGuard } from './authentication/helpers/auth.guard';
import { SnackBarService } from './services/snackbar.service';
import { GoogleMapsModule } from '@angular/google-maps'
import { NgxGalleryModule } from '@nomadreservations/ngx-gallery';
import { MomentModule } from 'ngx-moment';
import { NgxImageCompressService } from 'ngx-image-compress';
import { UploadxModule } from 'ngx-uploadx';

// Primeng imports
import { TableModule } from 'primeng/table';
import { ApiService } from './services/api.service';
import { ButtonModule } from 'primeng/button';
import { MatFileUploadModule } from 'mat-file-upload';
import { TagModule } from 'primeng/tag';
import { LocalStorageService } from './services/localstorage.service';
import { AbnormalityStorageService } from './services/abnormality-storage.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CalendarModule } from 'primeng/calendar';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    VerticalAppHeaderComponent,
    SpinnerComponent,
    AppBlankComponent,
    VerticalAppSidebarComponent,
    AppBreadcrumbComponent,
  ],
  imports: [
    MatFileUploadModule,
    BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(AppRoutes),
    GoogleMapsModule,
    HttpClientModule,
    NgxGalleryModule,
    TableModule,
    ButtonModule,
    MomentModule,
    UploadxModule,
    CalendarModule,
    NgxSkeletonLoaderModule,
    TagModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    ApiService,
    AuthenticationService,
    AuthGuard,
    SnackBarService,
    NgxImageCompressService,
    AbnormalityStorageService,
    LocalStorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: MAT_DATE_LOCALE, useValue: 'nl-NL' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
